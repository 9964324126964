<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '740px' }"
    header="Fomulário de Cargo"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
  >
    <div class="field">
      <label for="description">Descrição</label>
      <InputText
        id="description"
        v-model="v$.office.description.$model"
        maxlength="255"
        placeholder="Digite a descrição do tipo de cargos"
        :class="{ 'p-invalid': submitted && v$.office.description.$invalid }"
      />
      <small class="p-error" v-if="submitted && v$.office.description.$invalid"
        >Descrição do cargo é obrigatório.</small
      >
    </div>
    <template #footer>
      <Button
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.office.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>
<script>
//Models
import Office from "../../../models/office";

//Services
import OfficeService from "../../../service/office/office_service";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["officeSelected"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      office: new Office(),
      submitted: false,
      officeService: new OfficeService(),
    };
  },
  created() {},
  validations() {
    return {
      office: new Office().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.office.dialogForm;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.office.dialogForm = value;
      },
    },
  },
  methods: {
    send(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        if (this.office.id) {
          this.update();
        } else {
          this.create();
        }
      } else {
        return;
      }
    },
    create() {
      this.submitted = true;
      this.officeService
        .create(this.office)
        .then((data) => {
          if (data.status === 201) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro cadastrado com sucesso.",
              life: 3000,
            });
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.submitted = true;
      this.officeService
        .update(this.office)
        .then((data) => {
          if (data.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro alterado com sucesso.",
              life: 3000,
            });
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    hideDialog() {
      this.office = new Office();
      this.submitted = false;
      this.visibleDialog = false;
    },
    getData() {
      this.office = this.officeSelected;
    },
  },
};
</script>
<style scoped></style>
